// 选择多个添加人
<template>
  <div>
    <div class="member-content" @click.self="handleOpenDialog">
      <div
        class="selected-member"
        v-for="item in selectedList"
        :key="item.id"
        @click="handleDelete(item)"
      >
        <span v-if="type === '素材'">{{ item.title }}</span>
        <span v-else>{{ item.name }}</span>
        <i class="el-tag__close el-icon-close"></i>
      </div>
    </div>
    <el-dialog
      width="70%"
      append-to-body
      ref="transferDialog"
      :title="`选择${title}`"
      :visible.sync="dialogMemberVisible"
    >
      <pagoda-form-table-layout>
        <!-- 查询条件 -->
        <template slot="form">
          <el-form class="el-form-reset" inline v-model="formData">
            <el-form-item
              :label="`${title}`"
              prop="keyword"
              label-position="left"
              label-width="70px"
            >
              <el-input
                v-if="type === '素材'"
                :placeholder="`请输入${title}`"
                class="formdata-title"
                v-model="formData.title"
              ></el-input>
              <el-input
                v-else
                :placeholder="`请输入${title}`"
                v-model="formData.keyword"
              ></el-input>
            </el-form-item>
            <div class="pagoda-button-group">
              <el-button type="primary" @click="handleSearch">查询</el-button>
            </div>
          </el-form>
        </template>
        <template slot="table">
          <el-table
            ref="memberTable"
            @selection-change="selectionChange"
            :data="tableData"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              v-if="type === '素材'"
              :label="`${title}`"
              prop="title"
            ></el-table-column>
            <el-table-column
              v-else
              :label="`${title}`"
              prop="name"
            ></el-table-column>
          </el-table>
        </template>
        <!-- 分页 -->
        <template slot="pagination">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :current-page="pageNumber"
            :total="totalDataNum"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </template>
      </pagoda-form-table-layout>
      <div slot="footer" class="dialog-footer">
        <el-button @click="oncancle">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import customer from '@/services/api/customer'
import reach from '@/services/api/reach'
export default {
  name: 'memberTransfer',
  props: {
    disabled: Boolean,
    title: String,
    type: String
  },
  data() {
    return {
      dialogMemberVisible: false,
      formData: {
        title: '',
        keyword: '',
        page: 1,
        page_size: 10
      },
      tableData: [],
      selectedList: [],
      selectedList1: [],
      selectedIdList: [],
      totalDataNum: 0,
      pageSize: 10,
      pageNumber: 1
    }
  },
  methods: {
    handleSearch() {
      console.log('=====>查询')
      this.formData.page = 1
      this.formData.page_size = 10
      this.pageNumber = 1
      this.pageSize = 10
      this.getList()
    },
    getList() {
      const params = this.formData
      if (this.type === '素材') {
        reach
          .getProposalTitleList(params)
          .then((res) => {
            this.tableData = res.data.list || []
            this.totalDataNum = res.data.count || 0
          })
          .catch(() => {})
      } else {
        customer
          .getAddMember(params)
          .then((res) => {
            this.tableData = res.data.list || []
            this.totalDataNum = res.data.total_num || 0
          })
          .catch(() => {})
      }
    },
    // pageSize 改变时会触发
    handleSizeChange(val) {
      this.pageSize = val
      this.formData.page_size = val
      this.refreshTableData()
    },
    // currentPage 改变时会触发
    handleCurrentChange(val) {
      this.pageNumber = val
      this.formData.page = val
      this.getList()
    },
    // 刷新表格数据，重置回第一页
    refreshTableData() {
      this.pageNumber = 1
      this.formData.page = 1
      this.getList()
    },
    handleOpenDialog() {
      this.$emit('clearValidate')
      this.dialogMemberVisible = true
    },
    selectionChange(selection) {
      this.$emit('clearValidate')
      console.log('selectionChange', selection)
      this.selectedList1 = selection
      this.selectedIdList = selection.map((row) => row.id)
      // this.$emit('selectedList', this.selectedIdList)
    },
    handleDelete(obj) {
      this.selectedList.forEach((item, index) => {
        if (item.id === obj.id) {
          this.selectedList.splice(index, 1)
        }
      })
      // 获取的数据不会同步关联dialog里面的数据
      if (!obj.isGetData) {
        this.$refs.memberTable.toggleRowSelection(obj)
      }
      this.$emit('selectedList', this.selectedIdList)
    },
    handleConfirm() {
      this.dialogMemberVisible = false
      this.selectedList = this.selectedList1
      this.$emit('selectedList', this.selectedIdList)
      this.$emit('clearValidate')
    },
    oncancle() {
      console.log('点击了取消oncancle')
      this.selectedIdList = []
      this.dialogMemberVisible = false
      this.$emit('selectedList', [])
      this.$emit('clearValidate')
    }
  },
  created() {
    this.formData.title = ''
    this.formData.keyword = ''
    this.handleSearch()
  }
}
</script>
<style lang="less">
.member-content {
  min-height: 28px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  border-radius: 4px;
}
.selected-member {
  display: inline-block;
  background-color: #c1c3c6;
  color: #fff;
  border-radius: 3px;
  padding: 0 5px;
  margin: 3px;
  cursor: pointer;
}
.transfer-cont {
  display: flex;
  padding: 10px 0;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  .member-list {
    width: 50%;
    min-height: 200px;
    padding: 10px;
    border-right: 1px solid #dcdfe6;
    .search-cont {
      display: flex;
      input {
        border-radius: 4px 0 0 4px;
      }
      button {
        border-radius: 0 4px 4px 0;
      }
    }
  }
  .selected-list {
    padding: 10px;
    .selected-member {
      display: block;
    }
  }
  .select-member {
    display: block;
    background-color: #00c587;
    color: #fff;
    border-radius: 3px;
    padding: 0 5px;
    margin: 5px 0;
    cursor: pointer;
  }
}
</style>
