import AjaxService from '../ajax.service'
export default {
  // 获取客户分类
  getCustomerType() {
    return AjaxService.request({
      url: '/api/operation_get_customer_type',
      method: 'get'
    })
  },
  // 获取企业标签
  getCompanyTag() {
    return AjaxService.request({
      url: '/api/operation_get_label',
      method: 'get'
    })
  },
  // 获取客户状态
  getCustomerStatus() {
    return AjaxService.request({
      url: '/api/operation_get_customer_status',
      method: 'get'
    })
  },
  // 获取企业成员
  getAddMember(params) {
    return AjaxService.request({
      url: '/api/operation_get_member_list',
      method: 'post',
      params
    })
  },
  // 获取客户数据列表
  getCustomerList(params) {
    return AjaxService.request({
      url: '/api/operation_get_customer_list',
      method: 'post',
      params
    })
  },
  // 获取去重后的客户数
  getCustomerNum(params) {
    return AjaxService.request({
      url: '/api/operation_get_duplicate_removal_customer_num',
      method: 'post',
      params
    })
  },
  // 获取客户详情页内容
  getCustomerInfo(params) {
    return AjaxService.request({
      url: '/api/operation_get_customer_info',
      method: 'post',
      params
    })
  },
  // 获取客户已添加的员工
  getCustomerAddMember(params) {
    return AjaxService.request({
      url: '/api/operation_get_customer_add_member',
      method: 'post',
      params
    })
  },
  // 获取客户其他信息
  getCustomerOtherInfo(params) {
    return AjaxService.request({
      url: '/api/operation_get_customer_other_info',
      method: 'post',
      params
    })
  },
  // 获取客户企业标签
  getCustomerLabel(params) {
    return AjaxService.request({
      url: '/api/operation_get_customer_label',
      method: 'post',
      params
    })
  },
  // 获取客户动态列表
  getCustomerNews(params) {
    return AjaxService.request({
      url: '/api/operation_get_customer_news',
      method: 'post',
      params
    })
  }
}
