<template>
  <div class="customer-list">
    <pagoda-form-table-layout>
      <!-- 表单 -->
      <template slot="form">
        <el-form class="el-form-reset" inline>
          <el-form-item label="群名" prop="keyword">
            <el-input
              placeholder="请输入群名"
              v-model="formData.keyword"
            ></el-input>
          </el-form-item>
          <el-form-item label="群主选择" prop="members">
            <member-transfer
              title="群主"
              ref="memberTransfer"
              @selectedList="addSelectedList"
            ></member-transfer>
          </el-form-item>
          <el-form-item label="创建时间" prop="addDate">
            <el-date-picker
              v-model="formData.addDate"
              type="daterange"
              @change="handleChangeDate"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <!-- 表单操作按钮 -->
          <div class="pagoda-button-group">
            <el-button @click="handleReset">重置</el-button>
            <el-button type="primary" @click="handleSubmit">查询</el-button>
          </div>
        </el-form>
        <!-- <div class="total-number">共{{ totalDataNum }}个客户群</div> -->
      </template>
      <!-- 实现一屏展示时 一定要配置表格高度 -->
      <template slot="table" slot-scope="scope">
        <el-table
          ref="table"
          :data="tableData"
          :height="scope.height"
          v-loading="isTableLoading"
          element-loading-background="rgba(0, 0, 0, 0)"
        >
          <el-table-column prop="group_name" label="客户群名">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row.chat_id)">
                {{ scope.row.group_name }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="group_member_num"
            label="群人数"
          ></el-table-column>
          <el-table-column prop="group_leader" label="群主"></el-table-column>
          <el-table-column prop="add_time" label="创建时间"></el-table-column>
        </el-table>
      </template>
      <!-- 分页 -->
      <template slot="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :current-page="pageNumber"
          :total="totalDataNum"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </template>
    </pagoda-form-table-layout>
  </div>
</template>
<script>
import memberTransfer from '@/components/member-transfer'
import http from '@/services/api/community'
export default {
  data() {
    return {
      formData: {
        page: 1,
        page_size: 10
      },
      tableData: [],
      customerTypeList: [],
      customerStatusList: [],
      companyTags: [],
      totalDataNum: 0,
      customerNum: 0,
      pageSize: 10,
      pageNumber: 1,
      isTableLoading: false
    }
  },
  components: {
    memberTransfer
  },
  methods: {
    handleSubmit() {
      this.formData.page = 1
      this.formData.page_size = 10
      this.pageNumber = 1
      this.pageSize = 10
      this.getList()
    },
    getList() {
      const params = this.formData
      http
        .getGroupChatList(params)
        .then((res) => {
          this.tableData = res.data.list || []
          this.totalDataNum = res.data.total_num || 0
        })
        .catch(() => {})
    },
    handleReset() {
      this.$refs.memberTransfer.selectedList = []
      this.$refs.memberTransfer.tableData = []
      this.$refs.memberTransfer.formData.keyword = ''
      this.formData = {
        page: 1,
        page_size: 10
      }
      this.pageNumber = 1
      this.pageSize = 10
      this.getList()
    },
    // pageSize 改变时会触发
    handleSizeChange(val) {
      this.pageSize = val
      this.formData.page_size = val
      this.refreshTableData()
    },
    // currentPage 改变时会触发
    handleCurrentChange(val) {
      this.pageNumber = val
      this.formData.page = val
      this.getList()
    },
    // 刷新表格数据，重置回第一页
    refreshTableData() {
      this.pageNumber = 1
      this.formData.page = 1
      this.getList()
    },
    // 至详情页
    handleDetail(chatId) {
      this.$router.push({
        path: '/communityManageDetail',
        query: {
          chatId
        }
      })
    },
    handleChangeDate(arr = []) {
      this.formData.start_time = arr[0]
      this.formData.end_time = arr[1]
    },
    // 搜索添加成员
    addSelectedList(selectedList) {
      this.formData.members = selectedList
    }
  },
  created() {
    this.handleSubmit()
  },
  activated() {
    // 刷新列表数据
    this.refreshListData()
    this.$nextTick(() => {
      // 切换页面后表格偏移问题，对table进行重新布局
      this.$refs['table'].doLayout()
    })
  }
}
</script>

<style lang="less">
.customer-list {
  height: 100%;
  .el-date-editor--monthrange.el-input,
  .el-date-editor--monthrange.el-input__inner {
    width: 220px;
  }
}
.customer-type {
  display: flex;
  line-height: 40px;
  .el-image {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
}
.total-number {
  margin-top: 10px;
  padding: 10px 0 20px;
  border-top: 1px solid #e1e6eb;
}
.company-tag {
  display: inline-block;
  background-color: #00c587;
  color: #fff;
  border-radius: 3px;
  padding: 0 5px;
  margin: 3px;
}
</style>
