import AjaxService from '../ajax.service'
export default {
  // 获取社群数据列表
  getGroupChatList(params) {
    return AjaxService.request({
      url: '/api/operation_get_groupchat_list',
      method: 'post',
      params
    })
  },
  // 获取群成员列表
  getGroupChatMemberList(params) {
    return AjaxService.request({
      url: '/api/operation_get_groupchat_member_list',
      method: 'post',
      params
    })
  },
  // 获取群成员列表页群概览数据
  getGroupChatInfo(params) {
    return AjaxService.request({
      url: '/api/operation_get_groupchat_overview',
      method: 'post',
      params
    })
  }
}
