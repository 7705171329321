import AjaxService from '../ajax.service'
export default {
  // 获取素材类型
  getMaterialType(params) {
    return AjaxService.request({
      url: '/api/material_library/get_type',
      method: 'post',
      params
    })
  },
  // 获取素材列表
  getMaterialList(params) {
    return AjaxService.request({
      url: '/api/material_library/list',
      method: 'post',
      params
    })
  },
  // 删除素材/话术
  deleteMateria(params) {
    return AjaxService.request({
      url: '/api/material_library/del_content',
      method: 'post',
      params
    })
  },
  // 查看素材/话术
  getMaterialDetail(id) {
    return AjaxService.request({
      url: `/api/material_library/detail?id=${id}`,
      method: 'get'
    })
  },
  // 编辑素材/话术
  editMaterial(params) {
    return AjaxService.request({
      url: '/api/material_library/edit',
      method: 'post',
      params
    })
  },
  // 添加素材/话术
  addMaterial(params) {
    return AjaxService.request({
      url: '/api/material_library/add',
      method: 'post',
      params
    })
  },
  // 获取话术关键词列表;
  getKeyWordList(params) {
    return AjaxService.request({
      url: '/api/operation_get_keyword',
      method: 'post',
      params
    })
  },
  // 编辑关键词
  editKeyword(params) {
    return AjaxService.request({
      url: '/api/operation_edit_keyword',
      method: 'post',
      params
    })
  },
  // 删除关键词
  deleteKeyword(params) {
    return AjaxService.request({
      url: '/api/operation_delete_keyword',
      method: 'post',
      params
    })
  },
  // 删除关键字和话术的关联关系
  deleteKeywordRelation(params) {
    return AjaxService.request({
      url: '/api/operation_delete_relationship',
      method: 'post',
      params
    })
  },
  //  获取企业部门列表
  getGroupList(id) {
    // ?parentid=${id}
    return AjaxService.request({
      url: `/api/get_child_department`,
      method: 'get'
    })
  },
  // 获取部门成员
  getMember(params = {}) {
    let { id = '', page = 1, pageSize = 10, keyword = '' } = params
    return AjaxService.request({
      url: `/api/cw_members?id=${id}&page=${page}&page_size=${pageSize}&keyword=${keyword}`,
      method: 'get'
    })
  },
  // // 获取成员的好友和群列表
  getCustomList(params = {}) {
    let { type, id, page = 1, pageSize = 10, keyword = '' } = params
    // 127.0.0.1/operation_get_member_customer_list?type=1&id=1&keyword=&page=&page_size=
    return AjaxService.request({
      url: `/api/operation_get_member_customer_list?type=${type}&id=${id}&page=${page}&page_size=${pageSize}&keyword=${keyword}`,
      method: 'get'
    })
  },
  // // 消息列表
  getMessageList(params) {
    let { type, memberId, objectId, date, page = 1, pageSize = 10 } = params
    return AjaxService.request({
      url: `/api/operation_get_message_list?type=${type}&member_id=${memberId}&object_id=${objectId}&date=${date}&page=${page}&page_size=${pageSize}`,
      method: 'get'
    })
  },
  // 删除素材/话术中的图片
  deleteImage(params) {
    return AjaxService.request({
      url: '/api/material_library/del_image',
      method: 'post',
      params
    })
  },
  // 获取素材分组信息
  getMaterialGroup(params) {
    return AjaxService.request({
      url: '/api/material_library/get_group',
      method: 'post',
      params
    })
  },
  // 添加素材/话术分组
  addGroup(params) {
    return AjaxService.request({
      url: '/api/material_library/group_add',
      method: 'post',
      params
    })
  },
  // 编辑素材/话术分组
  editGroup(params) {
    return AjaxService.request({
      url: '/api/material_library/group_edit',
      method: 'post',
      params
    })
  },
  // 删除素材/话术分组
  deleteGroup(params) {
    return AjaxService.request({
      url: '/api/material_library/del_group',
      method: 'post',
      params
    })
  },
  // 营销建议列表页
  getProposalList(params) {
    return AjaxService.request({
      url: '/api/suggestions_library/list',
      method: 'post',
      params
    })
  },
  // 营销建议编辑页获取素材标题列表
  getProposalTitleList(params) {
    return AjaxService.request({
      url: '/api/suggestions_material/select_list',
      method: 'post',
      params
    })
  },
  // 删除营销建议
  deleteProposal(id) {
    return AjaxService.request({
      url: `/api/suggestions/delete?id=${id}`,
      method: 'get'
    })
  },
  // 查看营销建议
  getProposalDetail(id) {
    return AjaxService.request({
      url: `/api/suggestions/detail?id=${id}`,
      method: 'get'
    })
  },
  // 添加营销建议
  addProposal(params) {
    return AjaxService.request({
      url: '/api/suggestions/add',
      method: 'post',
      params
    })
  },
  // 编辑营销建议
  editProposal(params) {
    return AjaxService.request({
      url: '/api/suggestions/edit',
      method: 'post',
      params
    })
  },
  // 获取触达行为
  getTrigger(params) {
    return AjaxService.request({
      url: '/api/suggestions/get_trigger',
      method: 'post',
      params
    })
  }
}
